<template>
    <div>
            <v-container >
                <v-row
          align-center
          justify="center"
          fill-height
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-0 justify-center" >
               <v-card-title class="font-weight-black">
                Forgot Password
                </v-card-title>
                  <v-card-subtitle>
                    Enter your email
                    </v-card-subtitle>
                <v-spacer />
              <v-card-text>
                <v-form ref="forgotform" v-if="!show_change_password_form">
                  <v-text-field outlined
                    label="Email"
                    type="text"
                    ref="email"
                     v-model="forgot_form.email" name="email"
                     :rules="[rules.required,rules.email]"
                    :error-messages="forgot_form_errorMessages"            
                  />
                    <div style="margin-top:5px">
                        <v-btn style="color:#fff !important;" height="48" type="submit" :loading="apiloading" :disabled="apiloading" block color="secondary" @click.prevent="forgotPassword()">Send Me Link</v-btn>
                    </div>
                </v-form>
                  <v-form ref="change_pass_form" v-if="show_change_password_form">
                      <v-text-field outlined disabled
                                    label="Email"
                                    type="text"
                                    ref="email"
                                    v-model="change_password_form.email" name="email"
                                    :rules="[rules.required,rules.email]"
                                    :error-messages="forgot_form_errorMessages"
                      />
                      <v-text-field outlined
                                    label="Code"
                                    type="text"
                                    ref="code"
                                    v-model="change_password_form.code" name="email"
                                    :rules="[rules.required]"
                                    :error-messages="forgot_form_errorMessages"
                      />
                      <v-text-field
                              outlined
                              ref="password"
                              label="Password"
                              v-model="change_password_form.password"
                              name="password"
                              :append-icon="show_pass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                              @click:append="() => (show_pass = !show_pass)"
                              :type="show_pass ? 'text' : 'password'"
                              :rules="[rules.required]"
                      />
                      <v-btn style="color:#fff !important;" height="48" type="submit" :loading="apiloading" :disabled="apiloading" block color="secondary" @click.prevent="changePassword()">Change Password</v-btn>
                  </v-form>
              </v-card-text>
              <v-card-actions class="justify-center">
                  <v-btn text color="secondary" style="text-transform: capitalize" @click="retry()"> <span style="font-size:15px">Retry</span></v-btn>
                  <v-spacer></v-spacer>
                  <router-link :to="{name:'login'}" class="a-link">Back to Login</router-link>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        </v-container>
        <success text="Password Change Successfully"></success>
    </div>
</template>
<script>
import { formMixin } from '../../mixins/form'
import { loadingMixin } from '../../mixins/Loading'
// import EventBus from '../../services/event'
export default {
    inject:["Bus"],
    mixins:[formMixin,loadingMixin],
    data(){
        return{
            show_pass:false,
            forgot_form:{
                email:'',
                // source:'back'
            },
            change_password_form:{
                email:'',
                code:'',
                password:''
            },
            show_change_password_form:false,
            forgot_form_errorMessages:'',
            forgot_form_hasErrors:false,
            rules: {
                required: value => !!value || 'Required.',
                counter: value => value.length <= 20 || 'Max 20 characters',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
            }
        }
    },

    methods: {
        forgotPassword(){
            if(!this.$refs.forgotform.validate()){
                return;
            }
            this.change_password_form.email = this.forgot_form.email;
            this.$store.dispatch("forgotPasswordAction",this.forgot_form).then((res)=>{
                this.show_change_password_form = true;
            }).catch((e)=>{
                this.show_change_password_form = false;
            })

        },
        changePassword(){
            if(!this.$refs.change_pass_form.validate()){
                return;
            }
            this.$store.dispatch('changePasswordByForgotAction',this.change_password_form).then((res)=>{
                this.Bus.$emit("show_success",true);
                this.show_change_password_form = false;
                this.forgot_form={}
                this.change_password_form={}
                this.$router.push({name:'login'});
            }).catch((e)=>{
                this.show_change_password_form = true;
            })
        },
        retry(){
            this.show_change_password_form=false;
        }
    },
    
}
</script>
<style scoped>
    a{
        color:#379392 !important;
    }
    .a-link{
        color:#379392;
        text-decoration: none;
    }
</style>